<template>
  <div
    ref="rootElement"
    :class="[
      'dropdown',
      `dropdown--${variant}`
    ]"
  >
    <Button
      :class="['dropdown-button', { 'transparent': transparentButton }]"
      :variant="variant"
      @click="toggleMenu"
      :disabled="loading || disabled"
    >
      <Spinner
        v-if="loading"
        small
      />

      <Icon
        v-else-if="icon"
        :name="icon"
        :class="[
          'dropdown-button__icon',
          'dropdown-button__icon--left',
          { 'dropdown-button__icon--margin': isLabelAndIconPresent },
          { 'dropdown-button__icon--alert': icon === 'alert-cycle' },
        ]"
      />

      <span
        class="dropdown-button__label"
        v-if="label"
      >
        {{ label }}
      </span>
      <span
        v-else
        class="dropdown-placeholder"
      >
        {{ placeholder }}
      </span>

      <Icon
        v-if="!hideChevron"
        class="dropdown-button__icon dropdown-button__icon--chevron"
        :name="isMenuOpen ? 'chevron-up' : 'chevron-down'"
      />
    </Button>

    <DropdownMenu
      v-if="isMenuOpen"
      :right="right"
      :menu-top-value="menuTopValue"
    >
      <slot />
    </DropdownMenu>
  </div>
</template>

<script>
import { includes } from 'lodash';
import { ref, computed, provide } from 'vue';
import { onClickOutside } from '@vueuse/core';
import DropdownMenu from './DropdownMenu';
import Button from './Button';
import Icon from './Icon';
import Spinner from './Spinner';

export default {
  components: {
    DropdownMenu,
    Button,
    Icon,
    Spinner,
  },
  props: {
    variant: {
      type: String,
      default: 'secondary',
      validator: (value) => includes(['primary', 'secondary'], value),
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    hideChevron: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    transparentButton: {
      type: Boolean,
      default: false,
    },
    menuTopValue: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['menuToggled'],
  setup(props, { emit }) {
    const rootElement = ref(null);
    const isMenuOpen = ref(false);
    provide('isMenuOpen', isMenuOpen);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
      emit('menuToggled');
    };
    onClickOutside(rootElement, () => {
      isMenuOpen.value = false;
    });
    const isLabelAndIconPresent = computed(() => props.label && props.icon);

    return {
      rootElement,
      isMenuOpen,
      toggleMenu,
      isLabelAndIconPresent,
    };
  },
};
</script>

<style lang="scss">
.dropdown {
  position: relative;
  z-index: 300;

  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    width: 100%;

    &.transparent {
      border: none;
      box-shadow: none;
      background-color: transparent;
    }

    .dropdown-button__icon {
      width: 14px;
      height: 14px;
      stroke: #000;
      margin-right: 0;
    }

    .dropdown-button__label {
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .dropdown-button__icon--left.dropdown-button__icon--margin {
      margin-right: 5px;
    }

    .dropdown-button__icon--left.dropdown-button__icon--alert {
      svg {
        path {
          stroke: red;
        }
      }
    }

    .dropdown-button__icon--chevron {
      margin-left: 10px;
    }
  }

  .dropdown-placeholder {
    color: #A9A9A9;
  }
}

.dropdown.dropdown--primary {
  .dropdown-button {
    color: #fff;

    .dropdown-button__icon{
      stroke: #fff;
    }
  }
}
</style>
