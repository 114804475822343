import http from '@/services/http-client';
import { labelByMappingType } from '@/services/helpers/mappings-mapper';
import routes from '@/../config/env';

const { idm } = routes;

export const getEventSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const getCompetitorSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};
export const getRegionSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const getCompetitionSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const getPlayerSuggestedMappings = ({ data, url }) => {
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params: data,
  };
  return http(options)
    .then((response) => response.data)
    .catch((err) => Promise.reject(err));
};

export const approveEventMapping = (payload) => {
  const options = {
    method: 'post',
    url: `${idm}/idm/map-event`,
    data: payload,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveEventCompetitorMapping = (payload) => {
  const options = {
    method: 'post',
    url: `${idm}/idm/map-event-and-competitors`,
    data: payload,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createEventCompetitorMapping = (payload) => {
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-event-and-competitors`,
    data: payload,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveCompetitorMapping = (payload) => {
  const data = {
    competitorId: payload.competitorId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-competitor?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createCompetitorMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-competitor?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveRegionMapping = (payload) => {
  const data = {
    regionId: payload.regionId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-region`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createRegionMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-region`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approveCompetitionMapping = (payload) => {
  const data = {
    competitionId: payload.competitionId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-competition`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createCompetitionMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-competition`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const approvePlayerMapping = (payload) => {
  const data = {
    playerId: payload.playerId,
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-player?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const createPlayerMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  const options = {
    method: 'post',
    url: `${idm}/idm/map-new-player?sport=${payload.sport}`,
    data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const unmapMapping = (payload) => {
  const data = {
    mappingId: payload.mappingId,
  };
  let url;
  const isHuddleSelected = payload.selectedFeed === 'huddle';
  switch (payload.mappingType) {
  case 'EVENTS_MAPPING':
    data.eventId = isHuddleSelected ? payload.id : payload.editData?.row?.mappedEntity?.eventId;
    data.sport = payload.sport;
    url = 'event';
    break;
  case 'COMPETITORS_MAPPING':
    data.sport = payload.sport;
    url = 'competitor';
    break;
  case 'COMPETITIONS_MAPPING':
    data.competitionId = isHuddleSelected ? payload.id : payload.editData?.row?.mappedEntity?.competitionId;
    url = 'competition';
    break;
  case 'REGIONS_MAPPING':
    data.regionId = isHuddleSelected ? payload.id : payload.editData?.row?.mappedEntity?.regionId;
    url = 'region';
    break;
  case 'PLAYERS_MAPPING':
    data.sport = payload.sport;
    url = 'player';
    break;
  default:
    break;
  }
  const options = {
    method: 'post',
    url: `${idm}/idm/revert-${url}-mapping`,
    params: data,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const searchMappingById = ({
  searchValue,
  sport,
  mappingType,
  feed,
  selectedFeed,
}) => {
  const mappingTypeUrlValue = labelByMappingType[mappingType];
  const url = selectedFeed === 'huddle'
    ? `find-${mappingTypeUrlValue}-mapping-for-feed-by-extid`
    : `find-${mappingTypeUrlValue}-by-id`;

  let params = {
    feed,
    sport,
  };
  if (selectedFeed === 'huddle') {
    params = {
      ...params,
      extId: searchValue,
    };
  } else {
    params = {
      ...params,
      id: searchValue,
    };
  }
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};

export const searchMappingByName = ({
  searchValue,
  sport,
  mappingType,
  feed,
  selectedFeed,
  resultsPerSearch,
}) => {
  const mappingTypeUrlValue = labelByMappingType[mappingType];
  const url = selectedFeed === 'huddle'
    ? `find-${mappingTypeUrlValue}-mappings-for-feed-by-extref`
    : `find-${mappingTypeUrlValue}s-by-name`;

  let params = {
    feed,
    sport,
    minRank: resultsPerSearch,
  };
  if (selectedFeed === 'huddle') {
    params = {
      ...params,
      extRef: searchValue,
    };
  } else {
    params = {
      ...params,
      name: searchValue,
    };
  }
  const options = {
    method: 'get',
    url: `${idm}/idm/${url}`,
    params,
  };
  return http(options)
    .then()
    .catch((err) => Promise.reject(err));
};
