import {
  find, includes, filter,
  groupBy, each, map, uniqBy,
} from 'lodash';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  addHours,
  subHours,
  addDays,
  startOfDay,
  endOfDay,
} from 'date-fns';
import formatDate, { DateTypes } from '@/utils/format-date';
import sportIds from './sports';

const {
  SOCCER_ID,
  BASKETBALL_ID,
  FOOTBALL_ID,
  BASEBALL_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
} = sportIds;

export const feedsOrder = {
  huddle: 1,
  betgenius: 2,
  betradar: 3,
  lsports: 5,
  'nba.com': 6,
  rotowire: 7,
  audl: 8,
};

export const mappingTypes = {
  COMPETITORS_MAPPING: 'COMPETITORS_MAPPING',
  EVENTS_MAPPING: 'EVENTS_MAPPING',
  REGIONS_MAPPING: 'REGIONS_MAPPING',
  COMPETITIONS_MAPPING: 'COMPETITIONS_MAPPING',
  PLAYERS_MAPPING: 'PLAYERS_MAPPING',
};

export const subMappingTypes = {
  REGION: 'REGION',
  COMPETITION: 'COMPETITION',
  HOME_TEAM: 'HOME_TEAM',
  AWAY_TEAM: 'AWAY_TEAM',
};

export const feedTypes = {
  HUDDLE: 'huddle',
  BETGENIUS: 'betgenius',
  BETRADAR: 'betradar',
  LSPORTS: 'lsports',
};

export const labelByMappingType = {
  COMPETITORS_MAPPING: 'competitor',
  EVENTS_MAPPING: 'event',
  REGIONS_MAPPING: 'region',
  COMPETITIONS_MAPPING: 'competition',
  PLAYERS_MAPPING: 'player',
};

const getMappedEntity = (data, type) => {
  let mappedEntity = {};
  let typePropId = '';
  let typePropName = '';
  const {
    COMPETITORS_MAPPING,
    EVENTS_MAPPING,
    REGIONS_MAPPING,
    COMPETITIONS_MAPPING,
    PLAYERS_MAPPING,
  } = mappingTypes;

  switch (type) {
  case COMPETITORS_MAPPING:
    typePropId = 'competitorId';
    typePropName = 'name';
    break;
  case EVENTS_MAPPING:
    typePropId = 'eventId';
    typePropName = 'eventName';
    break;
  case REGIONS_MAPPING:
    typePropId = 'regionId';
    typePropName = 'regionName';
    break;
  case COMPETITIONS_MAPPING:
    typePropId = 'competitionId';
    typePropName = 'competitionName';
    break;
  case PLAYERS_MAPPING:
    typePropId = 'playerId';
    typePropName = 'name';
    break;
  default:
    break;
  }

  // Case in which we create a new mapping based on the extFeed data
  // but we don't set the mappingType object inside of the db
  if (!data.mappedEntity && data.mappingType === 'EXACT_MATCH') {
    mappedEntity = {
      [typePropId]: data.intId,
      [typePropName]: data.extRef,
    };
  } else {
    mappedEntity = data.mappedEntity;
  }
  return mappedEntity;
};

const mapEventsMappingRowData = (data, sourceType) => {
  if (sourceType === 'external') {
    return {
      name: data.extRef,
      id: data.extId,
      mappings: null,
      mappingType: data.mappingType,
      mappingId: data.mappingId,
      mappedEntity: getMappedEntity(data, mappingTypes.EVENTS_MAPPING),
      startsAtUtc: data.startsAtUtc,
      competitionName: data.competitionMapping?.extRef,
      createdAt: data.createdAt,
      createdBy: data.createdBy,
      updatedAt: data.updatedAt,
      updatedBy: data.updatedBy,
      competition: data.competitionMapping,
      homeTeam: find(data.eventCompetitorMappingsByEventMappingId.nodes, { competitorMapping: { extId: data.competitorExtIds[0] } })?.competitorMapping,
      awayTeam: find(data.eventCompetitorMappingsByEventMappingId.nodes, { competitorMapping: { extId: data.competitorExtIds[1] } })?.competitorMapping,
      region: data.competitionMapping?.regionMapping,
      competitorsFlipped: data.competitorsFlipped,
    };
  }
  return {
    name: data.eventName,
    source: data.source,
    id: data.eventId,
    mappings: data.mappings?.nodes,
    mappingType: null,
    mappingId: null,
    mappedEntity: null,
    startsAt: data.startsAt,
    competitionName: data.competition?.competitionName,
  };
};

const mapCompetitorsMappingRowData = (data, sourceType) => {
  if (sourceType === 'external') {
    return {
      id: data.extId,
      name: data.extRef,
      source: data.source,
      mappings: null,
      mappingType: data.mappingType,
      mappingId: data.mappingId,
      mappedEntity: getMappedEntity(data, mappingTypes.COMPETITORS_MAPPING),
    };
  }
  return {
    name: data.name,
    source: data.source,
    mappings: data.teamMappings?.nodes,
    mappingType: data.mappingType,
    mappedEntity: null,
    id: data.teamId,
  };
};

const mapRegionsMappingRowData = (data, sourceType) => {
  if (sourceType === 'external') {
    return {
      id: data.extId,
      name: data.extRef,
      source: data.source,
      mappings: null,
      mappingType: data.mappingType,
      mappingId: data.mappingId,
      mappedEntity: getMappedEntity(data, mappingTypes.REGIONS_MAPPING),
    };
  }
  return {
    name: data.regionName,
    source: data.source,
    mappings: data.regionMappings?.nodes,
    mappingType: data.mappingType,
    mappedEntity: null,
    id: data.regionId,
  };
};

const mapCompetitionsMappingRowData = (data, sourceType) => {
  if (sourceType === 'external') {
    return {
      id: data.extId,
      name: data.extRef,
      source: data.source,
      mappings: null,
      mappingType: data.mappingType,
      mappingId: data.mappingId,
      mappedEntity: getMappedEntity(data, mappingTypes.COMPETITIONS_MAPPING),
      region: data?.regionMapping?.extRef,
    };
  }
  return {
    name: data.competitionName,
    source: data.source,
    mappings: data.competitionMappings?.nodes,
    mappingType: data.mappingType,
    mappedEntity: null,
    id: data.competitionId,
    region: data?.region?.regionName,
  };
};

const mapPlayersMappingRowData = (data, sourceType) => {
  if (sourceType === 'external') {
    return {
      id: data.extId,
      name: data.extRef,
      source: data.source,
      mappings: null,
      mappingType: data.mappingType,
      mappingId: data.mappingId,
      mappedEntity: getMappedEntity(data, mappingTypes.PLAYERS_MAPPING),
      teams: data.playerTeamMapping,
      playerIndex: data.playerIndex,
    };
  }
  return {
    name: data.name,
    source: data.source,
    mappings: data.playerMappings?.nodes,
    mappingType: data.mappingType,
    mappedEntity: null,
    id: data.playerId,
    playerIndex: data.playerIndex,
  };
};

export const mappingRowData = ({ rowData, mappingType, sourceType }) => {
  switch (mappingType) {
  case mappingTypes.EVENTS_MAPPING:
    return mapEventsMappingRowData(rowData, sourceType);
  case mappingTypes.COMPETITORS_MAPPING:
    return mapCompetitorsMappingRowData(rowData, sourceType);
  case mappingTypes.REGIONS_MAPPING:
    return mapRegionsMappingRowData(rowData, sourceType);
  case mappingTypes.COMPETITIONS_MAPPING:
    return mapCompetitionsMappingRowData(rowData, sourceType);
  case mappingTypes.PLAYERS_MAPPING:
    return mapPlayersMappingRowData(rowData, sourceType);
  default:
    return rowData;
  }
};

export const getSportNameBySportId = (sportId) => {
  let sportLabel = '';
  switch (sportId) {
  case FOOTBALL_ID:
    sportLabel = 'Football';
    break;
  case BASEBALL_ID:
    sportLabel = 'Baseball';
    break;
  case BASKETBALL_ID:
    sportLabel = 'Basketball';
    break;
  case SOCCER_ID:
    sportLabel = 'Soccer';
    break;
  case ULTIMATE_ID:
    sportLabel = 'Ultimate';
    break;
  case HOCKEY_ID:
    sportLabel = 'Hockey';
    break;
  default:
    break;
  }
  return sportLabel;
};

export const getSportLabelBySportId = (sportId) => {
  let sportLabel = '';
  switch (sportId) {
  case FOOTBALL_ID:
    sportLabel = 'AMERICAN_FOOTBALL';
    break;
  case BASEBALL_ID:
    sportLabel = 'BASEBALL';
    break;
  case BASKETBALL_ID:
    sportLabel = 'BASKETBALL';
    break;
  case SOCCER_ID:
    sportLabel = 'SOCCER';
    break;
  case ULTIMATE_ID:
    sportLabel = 'ULTIMATE';
    break;
  case HOCKEY_ID:
    sportLabel = 'ICE_HOCKEY';
    break;
  default:
    break;
  }
  return sportLabel;
};

/*
  When fetching data for competitors and players, response object
  name is depedent on sport. Here we create object name
  based of the sportId.
*/
export const getResponseObjectNameBySportId = (sportId, mappingType) => {
  const sportLabel = getSportNameBySportId(sportId);
  return `all${sportLabel}${mappingType}Mappings`;
};

// MAPPINGS SECTION

export const advancedSettingsTypes = {
  TIME_DIFF: 'TIME_DIFF',
  MIN_SIM: 'MIN_SIM',
};

export const mappingActionTypes = {
  VIEW_HISTORY: 'VIEW_HISTORY',
  COPY_ID: 'COPY_ID',
  SWITCH_TEAMS: 'SWITCH_TEAMS',
  CREATE_NEW_MAPPING: 'CREATE_NEW_MAPPING',
};

export const splitTeams = (name) => name.split(' v ');
export const displayRowTime = (time, feed) => formatDate(time, DateTypes.DATE_TIME_SUGGESTED_MAPPING, feed === feedTypes.HUDDLE); // last param - for ext event mappings date is already in UTC
const displayTime = (time) => formatDate(time, DateTypes.DATE_TIME_SUGGESTED_MAPPING);
export const displayTimeDiff = (startTimeDeltaMillis) => {
  const minutes = Math.ceil(Math.abs((startTimeDeltaMillis / 1000) / 60));
  const prefix = startTimeDeltaMillis < 0 ? '-' : '+';
  return `${prefix} ${minutes}min`;
};
export const getLabelFromType = (mappingType, sliceBy = 1) => {
  const label = mappingType.split('_')[0].toLowerCase();
  return label.slice(0, label.length - sliceBy);
};
export const copyToClipboard = (textToCopy) => {
  if (navigator) {
    navigator.clipboard.writeText(textToCopy);
  }
};

export const getMappingSourceIcon = (selectedFeed, source) => {
  let icon = '';
  if (selectedFeed === feedTypes.HUDDLE) {
    switch (source) {
    case feedTypes.BETGENIUS:
      icon = 'huddle-betgenius';
      break;
    case feedTypes.LSPORTS:
      icon = 'huddle-lsports';
      break;
    case feedTypes.BETRADAR:
      icon = 'huddle-betradar';
      break;
    default:
      icon = 'huddle';
      break;
    }
  } else {
    icon = selectedFeed;
  }
  return icon;
};

export const createPopupConfig = ({
  editData,
  mappingType,
  selectedFeed,
  isEventList,
}) => {
  const config = {
    title: '',
    mappingTypeText: '',
    details: {
      name: '',
      icon: '',
      additionalInfo: '',
    },
    advancedFilters: [],
    subMappings: [],
  };

  const feedCapitalized = editData?.feed?.charAt(0).toUpperCase() + editData?.feed?.slice(1);
  switch (mappingType) {
  case mappingTypes.COMPETITORS_MAPPING:
    config.title = `Competitor mapping - ${feedCapitalized}`;
    config.mappingTypeText = 'Competitor';
    config.details = {
      name: editData.row.name,
      additionalInfo: `ID: ${editData.row.id}`,
    };
    config.advancedFilters = [advancedSettingsTypes.MIN_SIM];
    break;
  case mappingTypes.EVENTS_MAPPING:
    config.title = 'Event mapping';
    if (!isEventList) config.title += ` - ${feedCapitalized}`;
    config.mappingTypeText = 'Event';
    config.details = {
      name: `${splitTeams(editData.row.name)[0]} vs ${splitTeams(editData.row.name)[1]}`,
      additionalInfo: `Competition: ${editData.row.competitionName} · ${displayRowTime(editData.row.startsAt, editData.feed)}`,
    };
    config.advancedFilters = [advancedSettingsTypes.MIN_SIM, advancedSettingsTypes.TIME_DIFF];

    // Configuration used when we want to create a new mapping
    const eventRegionSubMappingType = editData.row.region?.mappingType;
    const eventCompetitionSubMappingType = editData.row.competition?.mappingType;
    const eventHomeTeamSubMappingType = editData.row.homeTeam?.mappingType;
    const eventAwayTeamSubMappingType = editData.row.awayTeam?.mappingType;
    config.subMappings = [
      {
        title: 'Region',
        type: subMappingTypes.REGION,
        mappingType: mappingTypes.REGIONS_MAPPING,
        data: editData.row.region,
        mapped: !eventRegionSubMappingType ? null : eventRegionSubMappingType === 'EXACT_MATCH',
        required: false,
      },
      {
        title: 'Competition',
        type: subMappingTypes.COMPETITION,
        mappingType: mappingTypes.COMPETITIONS_MAPPING,
        data: editData.row.competition,
        mapped: !eventCompetitionSubMappingType ? null : eventCompetitionSubMappingType === 'EXACT_MATCH',
        required: false,
      },
      {
        title: 'Home team',
        type: subMappingTypes.HOME_TEAM,
        mappingType: mappingTypes.COMPETITORS_MAPPING,
        data: editData.row.homeTeam,
        mapped: !eventHomeTeamSubMappingType ? null : eventHomeTeamSubMappingType === 'EXACT_MATCH',
        required: true,
      },
      {
        title: 'Away team',
        type: subMappingTypes.AWAY_TEAM,
        mappingType: mappingTypes.COMPETITORS_MAPPING,
        data: editData.row.awayTeam,
        mapped: !eventAwayTeamSubMappingType ? null : eventAwayTeamSubMappingType === 'EXACT_MATCH',
        required: true,
      },
    ];

    // If mapping type is null we don't want to show it
    config.subMappings = filter(config.subMappings, (sm) => sm.mapped !== null);
    break;
  case mappingTypes.REGIONS_MAPPING:
    config.title = `Region mapping - ${feedCapitalized}`;
    config.mappingTypeText = 'Region';
    config.details = {
      name: editData.row.name,
    };
    config.advancedFilters = [advancedSettingsTypes.MIN_SIM];
    break;
  case mappingTypes.COMPETITIONS_MAPPING:
    config.title = `Competition mapping - ${feedCapitalized}`;
    config.mappingTypeText = 'Competition';
    config.details = {
      name: editData.row.name,
      additionalInfo: `Region: ${selectedFeed === feedTypes.HUDDLE ? find(editData.row.mappings, { feed: editData.feed })?.regionMapping?.extRef : editData.row.region.extRef }`,
    };
    config.advancedFilters = [advancedSettingsTypes.MIN_SIM];

    // Configuration used when we want to create a new mapping
    const regionSubMappingType = editData.row.region?.mappingType;
    config.subMappings = [
      {
        title: 'Region',
        type: subMappingTypes.REGION,
        mappingType: mappingTypes.REGIONS_MAPPING,
        data: editData.row.region,
        mapped: !regionSubMappingType ? null : regionSubMappingType === 'EXACT_MATCH',
        required: false,
      },
    ];

    // If mapping type is null we don't want to show it
    config.subMappings = filter(config.subMappings, (sm) => sm.mapped !== null);
    break;
  case mappingTypes.PLAYERS_MAPPING:
    config.title = `Player mapping - ${feedCapitalized}`;
    config.mappingTypeText = 'Player';
    let teamInfo = {};
    if (selectedFeed === feedTypes.HUDDLE) {
      const exactMapping = find(editData.row.mappings, { feed: editData.feed, mappingType: 'EXACT_MATCH' });
      if (exactMapping) {
        teamInfo = exactMapping.playerTeamMapping?.nodes;
      }
      const fuzzyMapping = find(editData.row.mappings, { feed: editData.feed, mappingType: 'FUZZY_MATCH' });
      if (!exactMapping && fuzzyMapping) {
        teamInfo = fuzzyMapping.playerTeamMapping?.nodes;
      }
    } else {
      teamInfo = editData.row?.teams?.nodes;
    }
    config.details = {
      name: editData.row.name,
    };
    if (teamInfo && teamInfo.length) {
      config.details.additionalInfo = 'Teams: ';
      const groupedTeams = groupBy(teamInfo, 'team.extRef');
      each(groupedTeams, (discriminators, teamName) => {
        if (config.details.additionalInfo.length > 7) config.details.additionalInfo += ', '; // avoid adding , to first team
        config.details.additionalInfo += teamName;
        const discriminatorsNumbers = filter(discriminators, (item) => item.discriminator && item.discriminator !== 'null');
        if (!discriminatorsNumbers.length) return;
        config.details.additionalInfo += '(';
        each(discriminatorsNumbers, (item, index) => {
          config.details.additionalInfo += `${index === 0 ? '' : ', '}${item.discriminator}`;
        });
        config.details.additionalInfo += ')';
      });
    }
    config.advancedFilters = [advancedSettingsTypes.MIN_SIM];
    break;

  default: break;
  }

  config.details.icon = getMappingSourceIcon(selectedFeed, editData.row.source);

  return config;
};

export const createPayloadForFetchingMapping = ({ props, filtersData }) => {
  const data = {
    feed: props.editData.feed,
    minRank: parseInt(filtersData.value.rank, 10),
    minSimilarity: parseFloat(filtersData.value.similarity / 100) || 0.1, // convert % to decimal
    sport: props.selectedSportLabel,
  };
  let url = '';

  switch (props.mappingType) {
  case mappingTypes.EVENTS_MAPPING:
    url = 'find-event-matches-for-mapping';
    data.mappingId = props.editData.row.mappingId;
    data.maxDeltaMillis = parseInt(filtersData.value.time, 10) * 60 * 1000; // convert mins to ms
    if (props.editData.feed !== feedTypes.HUDDLE) {
      data.feed = props.eventListSelectedFeed ? props.eventListSelectedFeed.feed : props.editData.feed;
      data.eventId = props.editData.row.id;
      delete data.mappingId;
      url = 'find-event-matches';
    }
    break;
  case mappingTypes.COMPETITORS_MAPPING:
    url = 'find-competitor-matches-for-mapping';
    data.mappingId = props.editData.row.mappingId;
    if (props.editData.feed !== feedTypes.HUDDLE) {
      data.competitorId = props.editData.row.id;
      delete data.mappingId;
      url = 'find-competitor-matches';
    }
    break;
  case mappingTypes.REGIONS_MAPPING:
    delete data.sport;
    url = 'find-region-matches-for-mapping';
    data.mappingId = props.editData.row.mappingId;
    if (props.editData.feed !== feedTypes.HUDDLE) {
      data.regionId = props.editData.row.id;
      delete data.mappingId;
      url = 'find-region-matches';
    }
    break;
  case mappingTypes.COMPETITIONS_MAPPING:
    url = 'find-competition-matches-for-mapping';
    data.mappingId = props.editData.row.mappingId;
    if (props.editData.feed !== feedTypes.HUDDLE) {
      data.competitionId = props.editData.row.id;
      delete data.mappingId;
      url = 'find-competition-matches';
    }
    break;
  case mappingTypes.PLAYERS_MAPPING:
    url = 'find-player-matches-for-mapping';
    data.mappingId = props.editData.row.mappingId;
    if (props.editData.feed !== feedTypes.HUDDLE) {
      data.playerId = props.editData.row.id;
      delete data.mappingId;
      url = 'find-player-matches';
    }
    break;

  default: break;
  }

  return { data, url };
};

export const createMappingInfo = ({ props, mappingTypeText }) => {
  const feedMapping = find(props.editData.row?.mappings, { feed: props.editData.feed }) || props.editData.row;
  if (!feedMapping) return '';
  let operation = '';
  if (feedMapping.createdBy && feedMapping.createdAt) operation = 'created';
  if (feedMapping.updatedBy && feedMapping.updatedAt) operation = 'updated';

  if (!operation) return '';

  const updatedTimeSeconds = Math.floor((new Date() - zonedTimeToUtc(feedMapping[`${operation}At`], 'UTC')) / 1000);

  const intervals = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 },
  ];

  const foundInterval = find(intervals, (i) => updatedTimeSeconds >= i.seconds);
  if (!foundInterval) return '';
  const interval = Math.floor(updatedTimeSeconds / foundInterval.seconds);
  const intervalLabel = `${interval} ${foundInterval.label}${interval >= 2 ? 's' : ''}`;

  const automaticUpdate = includes(feedMapping[`${operation}By`], 'service');
  return `This ${mappingTypeText} mapping was ${automaticUpdate ? 'automatically' : ''} ${operation} ${intervalLabel} ago by ${automaticUpdate ? feedMapping[`${operation}By`] : 'user'}`;
};

export const createMapping = ({ props, flippedEvents, isEventList }) => {
  const { VIEW_HISTORY, COPY_ID, SWITCH_TEAMS } = mappingActionTypes;
  const mapping = {
    similarity: 0,
    title: '',
    subtitle: '',
    sport: '',
    actions: [],
  };

  mapping.similarity = props.suggestedMapping.similarity;
  mapping.actions = [
    {
      label: `View ${getLabelFromType(props.mappingType)} history`,
      actionType: VIEW_HISTORY,
    },
    {
      label: 'Copy ID',
      actionType: COPY_ID,
    },
  ];
  switch (props.mappingType) {
  case mappingTypes.EVENTS_MAPPING:
    // competitor mappings are wrong in backend for now, so we are gonna use competitors for when huddle is not selected feed
    if (props.selectedFeed.feed !== feedTypes.HUDDLE && !isEventList) {
      mapping.title = `
      ${props.suggestedMapping.competitors[flippedEvents ? 1 : 0]?.name}
      vs
      ${props.suggestedMapping.competitors[flippedEvents ? 0 : 1]?.name}
    `;
    } else {
      mapping.title = `
      ${props.suggestedMapping.mapping.competitorMappings[flippedEvents ? 1 : 0]?.extRef}
      vs
      ${props.suggestedMapping.mapping.competitorMappings[flippedEvents ? 0 : 1]?.extRef}
    `;
    }

    mapping.subtitle = `
      ${displayTime(props.suggestedMapping.startsAt)}
      ${displayTimeDiff(props.suggestedMapping.startTimeDeltaMillis)}
    `;

    mapping.actions.push({
      label: 'Switch teams',
      actionType: SWITCH_TEAMS,
    });
    break;
  case mappingTypes.COMPETITORS_MAPPING:
    mapping.title = props.suggestedMapping.mapping.extRef;
    // mapping.subtitle = 'Competitions: TODO!';
    break;
  case mappingTypes.REGIONS_MAPPING:
    mapping.title = props.suggestedMapping.mapping.extRef;
    break;
  case mappingTypes.COMPETITIONS_MAPPING:
    mapping.title = props.suggestedMapping.mapping.extRef;
    mapping.subtitle = `Region: ${props.suggestedMapping.mapping.regionName}`;
    break;
  case mappingTypes.PLAYERS_MAPPING:
    mapping.title = props.suggestedMapping.mapping.extRef;
    const teamInfo = props.suggestedMapping.mapping.previousAppearances;
    if (teamInfo.length) {
      mapping.subtitle = 'Teams: ';
      const teams = uniqBy(map(teamInfo, (team) => ({
        ...team,
        nameAndNumber: `${team.competitorName}-${team.discriminator}`,
      })), 'nameAndNumber');
      teams.forEach((item, index) => {
        mapping.subtitle += `${item.competitorName} (${item.discriminator})${index < (teams.length - 1) ? ', ' : ''}`;
      });
    }
    break;

  default: break;
  }
  return mapping;
};

export const parseMappingTypeFromRouteName = (routeName) => {
  switch (routeName) {
  case 'eventsMapping':
    return mappingTypes.EVENTS_MAPPING;
  case 'competitorsMapping':
    return mappingTypes.COMPETITORS_MAPPING;
  case 'regionsMapping':
    return mappingTypes.REGIONS_MAPPING;
  case 'competitionsMapping':
    return mappingTypes.COMPETITIONS_MAPPING;
  case 'playersMapping':
    return mappingTypes.PLAYERS_MAPPING;
  default:
    return '';
  }
};

export const getPlayerMappingFieldName = (sportsLabel) => {
  switch (sportsLabel) {
  case 'BASEBALL':
    return 'baseballPlayerMapping';
  case 'AMERICAN_FOOTBALL':
    return 'footballPlayerMapping';
  case 'BASKETBALL':
    return 'basketballPlayerMapping';
  case 'ICE_HOCKEY':
    return 'hockeyPlayerMapping';
  case 'SOCCER':
    return 'soccerPlayerMapping';
  case 'ULTIMATE':
    return 'ultimatePlayerMapping';
  default:
    return '';
  }
};

export const mapEventMappingSummary = (mappingDetails) => {
  const getMappingType = (entity) => entity?.mappingType ?? 'NO_MATCH';
  const getBulkMappingType = (array) => {
    if (!array.length) return 'NO_MATCH';

    const counts = array.reduce(
      (output, entry) => ({
        ...output,
        [entry.mappingType]: output[entry.mappingType] + 1,
      }),
      {
        EXACT_MATCH: 0,
        FUZZY_MATCH: 0,
        NO_MATCH: 0,
      },
    );

    if (counts.EXACT_MATCH === array.length) return 'EXACT_MATCH';
    if (counts.NO_MATCH === array.length) return 'NO_MATCH';
    return 'FUZZY_MATCH';
  };

  return {
    event: getMappingType(mappingDetails.event),
    region: getMappingType(mappingDetails.region),
    competition: getMappingType(mappingDetails.competition),
    awayCompetitor: getMappingType(mappingDetails.awayCompetitor),
    homeCompetitor: getMappingType(mappingDetails.homeCompetitor),
    awaySquad: getBulkMappingType(mappingDetails.awaySquad),
    homeSquad: getBulkMappingType(mappingDetails.homeSquad),
  };
};

const createTraverseScreenParent = (screenConfigs) => {
  const traverseScreenParent = (screenType, allParentScreenTypes = []) => {
    const parentScreenType = screenConfigs[screenType].parent;
    if (!parentScreenType) return [screenType, ...allParentScreenTypes];
    return traverseScreenParent(parentScreenType, [screenType, ...allParentScreenTypes]);
  };
  return traverseScreenParent;
};

export const createBreadcrumbs = (screenConfigs, screenType) => {
  if (!screenConfigs[screenType]) throw new Error(`Cannot create breadcrumbs, invalid screen type: ${screenType}`);
  const traverseScreenParent = createTraverseScreenParent(screenConfigs);
  return traverseScreenParent(screenType);
};

export const parseMappingDateOption = (value) => {
  const currentTime = new Date();
  const parsedDates = { startDate: currentTime, endDate: endOfDay(addDays(currentTime, value)) };
  const timezoneOffset = currentTime.getTimezoneOffset() / 60;
  if (value <= 0) {
    parsedDates.startDate = startOfDay(addDays(currentTime, value));
  }
  if (value < 0) {
    parsedDates.endDate = endOfDay(addDays(currentTime, -1));
  }
  if (value === 0.2) { // incoming 2 hours option
    parsedDates.endDate = addHours(currentTime, 2);
  }

  const liveEventsOffset = value > 0 ? 2 : 0;
  if (timezoneOffset >= 0) {
    parsedDates.startDate = addHours(parsedDates.startDate, timezoneOffset - liveEventsOffset);
    parsedDates.endDate = addHours(parsedDates.endDate, timezoneOffset);
  } else {
    parsedDates.startDate = subHours(parsedDates.startDate, Math.abs(timezoneOffset) + liveEventsOffset);
    parsedDates.endDate = subHours(parsedDates.endDate, Math.abs(timezoneOffset));
  }

  return parsedDates;
};
