import { map, toLower } from 'lodash';
import http from '@/services/http-client';
import routes from '@/../config/env';

const { paramsManager } = routes;

export const getPlayerSetupDetails = async ({ sport = '', eventId, mode = 'active' }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/player-params/${toLower(mode)}/${eventId}`,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getIndividualPlayerParams = async ({
  sport = '',
  eventId,
  mode,
  playerIds,
}) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/player-params/${toLower(mode)}-player-params`,
      params: {
        eventId,
        playerIds,
      },
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const setPlayerParams = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/submit`,
      data,
    });
    return response;
  } catch (error) {
    throw Error(error);
  }
};

export const getPriceOnDemand = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/price-on-demand`,
      data,
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const getAvailableMarkets = async ({ sport = '' }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/player-params/available-markets`,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getGameAvailableMarkets = async ({ sport = '' }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/game-params/available-markets`,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

export const getGameSelectedMarkets = async ({ sport = '', eventId = '' }) => {
  try {
    const response = await http({
      url: `${paramsManager}/${sport}/game-params/active/${eventId}`,
      method: 'get',
    });
    return response.data?.selectedMarkets;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const setGameSelectedMarkets = async ({
  sport = '',
  eventId = '',
  fullTemplate,
  marketTemplateIds,
}) => {
  try {
    const response = await http({
      url: `${paramsManager}/${sport}/game-params/${eventId}/selected-markets`,
      method: 'post',
      data: {
        fullTemplate,
        marketTemplateIds,
      },
    });
    return response.data?.selectedMarkets;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const suspendPlayerMarkets = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/markets/suspend`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const batchSuspendPlayerMarkets = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/markets/batch-suspend`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const unsuspendPlayerMarkets = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/markets/unsuspend`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const batchUnsuspendPlayerMarkets = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/markets/batch-unsuspend`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getProjectedPlayerParams = async ({ sport = '', eventId, addedPlayers = null }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/player-params/projected-player-params`,
      params: {
        eventId,
        playerIds: addedPlayers.join(','),
      },
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const fetchPlayerProjections = async ({ sport = '', eventId }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/fetch-projections`,
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const fetchGameProjections = async ({ sport = '', eventId }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/game-params/${eventId}/fetch-projections`,
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const fetchPlayerParamFindId = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/${eventId}/player-paramfind`,
      data,
    });
    return response.data.paramFindId;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const fetchPlayerParamsOnDemand = async ({ sport = '', eventId, paramFindId }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/player-params/${eventId}/player-params-on-demand/${paramFindId}`,
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const getGameParams = async ({ sport = '', eventId, mode }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/game-params/${toLower(mode)}/${eventId}`,
    });
    return response.data;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const submitGameParams = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/game-params/${eventId}/submit`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getGameParamsList = async ({ sport = '', eventIds, mode = 'manual' }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/game-params/${toLower(mode)}?eventIds=${eventIds.join(',')}`,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const getGameParamsPriceOnDemand = async ({
  sport = '', eventId, data, withPlayers,
}) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/game-params/${eventId}/price-on-demand${withPlayers ? '/with-players' : ''}`,
      data,
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const fetchGameParamFindId = async ({ sport = '', eventId, data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/game-params/${eventId}/paramfind`,
      data,
    });
    return response.data.paramFindId;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const fetchGameParamsOnDemand = async ({ sport = '', eventId, paramFindId }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/game-params/${eventId}/params-on-demand/${paramFindId}`,
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};

export const getTeamLineupPreset = async ({ sport = '', teamId }) => {
  try {
    const response = await http({
      method: 'get',
      url: `${paramsManager}/${sport}/player-params/lineup/team/${teamId}`,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const saveTeamLineupPreset = async ({ sport = '', data }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/player-params/lineup`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const updateTeamLineupPreset = async ({ sport = '', lineupId, data }) => {
  try {
    const response = await http({
      method: 'put',
      url: `${paramsManager}/${sport}/player-params/lineup/${lineupId}`,
      data,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const deleteTeamLineupPreset = async ({ sport = '', lineupId }) => {
  try {
    const response = await http({
      method: 'delete',
      url: `${paramsManager}/${sport}/player-params/lineup/${lineupId}`,
    });
    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

export const setLimitedOffer = async ({ sport = '', eventId, isLimitedOffer }) => {
  try {
    const response = await http({
      method: 'post',
      url: `${paramsManager}/${sport}/game-params/${eventId}/limited-offer`,
      data: {
        limitedOffer: isLimitedOffer,
      },
    });
    return response;
  } catch (e) {
    const error = new Error();
    error.statusCode = e.response.status;
    error.messages = map(e.response?.data?.errors, (item) => item.detail);
    throw error;
  }
};
