<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.2071 1.29289C13.5976 1.68342 13.5976 2.31658 13.2071 2.70711L12.9103 3.00396C17.4882 3.68522 21 7.63249 21 12.4C21 15.5587 19.4574 18.3572 17.0889 20.0828C16.6425 20.4081 16.017 20.3098 15.6918 19.8635C15.3665 19.4171 15.4648 18.7916 15.9111 18.4664C17.7853 17.101 19 14.8924 19 12.4C19 8.75579 16.4009 5.71863 12.9553 5.0411L13.2071 5.29289C13.5976 5.68342 13.5976 6.31658 13.2071 6.70711C12.8166 7.09763 12.1834 7.09763 11.7929 6.70711L9.79289 4.70711C9.40237 4.31658 9.40237 3.68342 9.79289 3.29289L11.7929 1.29289C12.1834 0.902369 12.8166 0.902369 13.2071 1.29289ZM7.30824 4.93654C7.63345 5.38292 7.53523 6.00842 7.08886 6.33364C5.21475 7.69905 4 9.90761 4 12.4C4 16.0442 6.59909 19.0814 10.0447 19.7589L9.79289 19.5071C9.40237 19.1166 9.40237 18.4834 9.79289 18.0929C10.1834 17.7024 10.8166 17.7024 11.2071 18.0929L13.2071 20.0929C13.5976 20.4834 13.5976 21.1166 13.2071 21.5071L11.2071 23.5071C10.8166 23.8976 10.1834 23.8976 9.79289 23.5071C9.40237 23.1166 9.40237 22.4834 9.79289 22.0929L10.0897 21.796C5.51184 21.1148 2 17.1675 2 12.4C2 9.24131 3.54258 6.44281 5.91114 4.71716C6.35752 4.39194 6.98302 4.49017 7.30824 4.93654Z"
      fill="#191414"
    />
    <path
      d="M8.33 15.8C8.27 15.8 8.22 15.78 8.18 15.74C8.14 15.6934 8.12 15.6434 8.12 15.59C8.12 15.5567 8.12333 15.5267 8.13 15.5L10.51 9.07005C10.53 8.99672 10.57 8.93338 10.63 8.88005C10.69 8.82672 10.7733 8.80005 10.88 8.80005H12.16C12.2667 8.80005 12.35 8.82672 12.41 8.88005C12.47 8.93338 12.51 8.99672 12.53 9.07005L14.91 15.5C14.9167 15.5267 14.92 15.5567 14.92 15.59C14.92 15.6434 14.9 15.6934 14.86 15.74C14.82 15.78 14.77 15.8 14.71 15.8H13.67C13.57 15.8 13.4967 15.7767 13.45 15.73C13.4033 15.6834 13.3733 15.64 13.36 15.6L12.93 14.48H10.11L9.68 15.6C9.66666 15.64 9.63666 15.6834 9.59 15.73C9.54333 15.7767 9.47 15.8 9.37 15.8H8.33ZM10.48 13.19H12.55L11.52 10.31L10.48 13.19Z"
      fill="#191414"
    />
  </svg>
</template>

<style lang="scss" scoped>
svg {
  width: 100%;
  height: 100%;
}
</style>
